import { defineStore, acceptHMRUpdate } from 'pinia'
import pinia from '@plugins/pinia'
import axios from '@libs/axios'
import useWebStore from '@stores/web'
import { views } from '@src/modules'
import type { User } from '../models/User'
import type { Route } from '../models/Route'
import type { ResourceArray } from '../types/resource'

export type UserState = {
    locale: string
    user: User | null
    qtdDefaultRows: number
    routeItems: Route[]
    notifications: []
    loadingRoutes: boolean
    isAdmin: boolean
    isLoggedIn: boolean
    modules: []
}

const useUserStore = defineStore('user', {
    persistedState: {
        persist: true,
    },
    state: () =>
        ({
            locale: (
                navigator.language ||
                String(process.env.APP_LOCALE) ||
                'en'
            ).replace('-', '_'),
            user: null,
            qtdDefaultRows: 20,
            routeItems: [],
            notifications: [],
            loadingRoutes: false,
            isAdmin: false,
            isLoggedIn: false,
            modules: [], //Módulos que o cliente logado tem acesso
        }) as UserState,
    actions: {
        changeLocale(locale: string) {
            this.locale = locale
            axios.defaults.headers.common['locale'] = locale
            axios.defaults.headers.common['Accept-Language'] = locale
            document.querySelector('html')?.setAttribute('lang', locale)
        },
        async updateModules() {
            const webStore = useWebStore(pinia)

            if (!this.isLoggedIn) {
                return
            }

            if (this.isAdmin) {
                this.modules = []
                return
            }
            try {
                this.modules = (
                    await webStore.get({
                        url: 'core/info/business/modules',
                        locale: '[Get Modules from Business of User]',
                        silent: true,
                    })
                ).data.data
            } catch (e) {
                this.modules = []
            }
        },
        async loginAdmin(user: User) {
            let response = await axios.post('/auth/login/admin', {
                email: user.email,
                password: user.password,
            })
            if (response.data.user) {
                this.user = response.data.user
                this.isAdmin = true
                this.isLoggedIn = true
            }

            return response
        },
        async loginUser(user: User) {
            let response = await axios.post('/auth/login', {
                email: user.email,
                password: user.password,
            })
            if (response.data.user) {
                this.user = response.data.user
                this.isAdmin = false
                this.isLoggedIn = true
            }

            return response
        },
        async logout() {
            const url = this.isAdmin ? '/auth/logout/admin' : '/auth/logout'
            const wasAdmin = this.isAdmin
            this.user = null
            this.isAdmin = false
            this.isLoggedIn = false
            this.modules = []

            this.updateModules()
            await axios.post(url)
            await this.updateRoutes()
            window.router.push(wasAdmin ? '/login/admin' : '/login/user')
        },
        async refreshToken() {
            const url = this.isAdmin ? '/auth/refresh/admin' : '/auth/refresh'
            try {
                const response = await axios.get(url)
                this.user = response.data.user
                this.isLoggedIn = true

                return response
            } catch (error) {
                this.logout()

                throw error
            }
        },
        async updateRoutes() {
            const webStore = useWebStore(pinia)

            this.loadingRoutes = true

            if (!this.isLoggedIn) {
                if (window.router) window.updateRouter()
                return
            }
            if (this.isAdmin) {
                this.routeItems = [] as Route[]
                const result = await Promise.all(
                    views['Admin'].map(async (element: any) => {
                        return await element.import()
                    })
                )
                for (const view of result as any[]) {
                    const page = view.default
                    let route = {
                        name: page.name,
                        location: 'Admin@' + page.name,
                        title: 'Admin.routes.' + page.name,
                        icon: page.icon || 'mdi-account-question-outline ',
                        to: page.path || '/admin/' + page.name,
                        path: page.path || '/admin/' + page.name,
                    } as Route

                    if (page.fatherDir) {
                        let father = this.routeItems.find((aux) => {
                            return aux.title == 'Admin.routes.' + page.fatherDir
                        })
                        if (father) {
                            if (!father.routes) father.routes = []
                            father.routes.push(route)
                        } else {
                            father = {
                                title: 'Admin.routes.' + page.fatherDir,
                                icon: 'mdi-folder',
                                routes: [route],
                            } as Route
                            this.routeItems.push(father)
                        }
                    } else {
                        this.routeItems.push(route)
                    }
                }
            } else {
                try {
                    const response = await webStore.get<ResourceArray<Route>>({
                        url: 'core/info/routes',
                        locale: '[Get Routes User]',
                    })
                    this.routeItems = response.data.data
                } catch (response) {
                    this.routeItems = []
                }
            }

            if (window.router) window.updateRouter()
            this.loadingRoutes = false
        },
    },
})

export default useUserStore
