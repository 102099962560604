import { defineStore, acceptHMRUpdate } from 'pinia'
import useWebStore from './web'
import pinia from '../plugins/pinia'
import theme, { calculatePalette } from '../helper/theme'
import useUserStore from './user'

const useLayoutStore = defineStore('layout', {
    state: () => ({
        drawer: true,
        dark: false,
        theme: theme,
        mini: false,
        clientType: 'client',
    }),
    actions: {
        async updateTheme() {
            const webStore = useWebStore(pinia)
            const userStore = useUserStore(pinia)

            webStore
                .get({
                    url:
                        'core/config/layout' +
                        (userStore.isAdmin ? '/admin' : ''),
                    locale: '[App@UpdateTheme]',
                    silent: true,
                })
                .then((response) => {
                    let obj = calculatePalette(response.data.data.theme.light)
                    let light = { ...this.theme.light, ...obj }
                    this.clientType = response.data.data.client_type

                    this.theme = {
                        light,
                        dark: this.theme.dark,
                    }
                })
                .catch((responseError) => {})
        },
    },
})

export default useLayoutStore
