import { defineStore } from 'pinia'

const getDefaultState = () => {
    return {
        register: {
            month: [],
            lat: 0,
            long: 0,

            seller: {},
            pricePerWp: 0,
            comission: 0,

            potency: undefined,
            losses: 5,
            efficiency: 98,
            azimuth: undefined,
            tilt: undefined,

            realPotency: undefined,
            oldRealPotency: undefined,
            realEfficiency: 98,

            kitsSelecionados: [],
            equipamentos: [],

            valorDeServiço: -1,
            valorDeCompraEquipamentos: undefined,
            valorDeVendaEquipamentos: undefined,
            valorDeComissao: undefined,

            description: '',

            simulacao: undefined,

            status: undefined,

            client: {},

            simulation: {
                custoKwh: 1,
                crescimento: 5,
                degradacao: 0.5,
                anos: 25,
            },
        },

        apiKey: process.env.NREL_GOV_KEY,
    }
}

export default defineStore('solar', {
    state: getDefaultState,
    actions: {
        resetState() {
            Object.assign(this, getDefaultState())
        },
    },
})
