import { defineStore, acceptHMRUpdate } from 'pinia'
import type { BreadCrumb } from '../types/BreadCrumb'
import type { Lang } from '../types/lang'

export type AppState = {
    langs: Lang[]
    mini: boolean
    lazyLoadingRoute: boolean
    routerStack: BreadCrumb[]
}

const useAppStore = defineStore('app', {
    state: () =>
        ({
            langs: window.LANGS_PHP,
            mini: false,
            lazyLoadingRoute: false,
            routerStack: [],
        }) as AppState,
})

export default useAppStore
